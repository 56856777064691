import styled from 'styled-components';
import {BorderRadius, h4, mediaQueries} from '@/theme/lib';


export const StyledCarousel = styled.div`
  grid-column: 1 / span 5;
  width: 100%;

  h2 {
    color: ${(props) => props.theme.colors.carousel.mainTitleText};
    margin-bottom: 1.625rem;

    ${mediaQueries.lg} {
      margin-bottom: 1.5rem;
    }
  }

  .swiper {
    overflow: visible;
  }

  li {
    list-style: none;
  }

  ${mediaQueries.lg} {
    grid-column: 1 / span 10;
  }

  .swiper-slide {
    width: 256px;

    ${mediaQueries.smHero} {
      width: 320px;
    }

    ${mediaQueries.md} {
      width: 590px;
    }
  }
`;

export const StyledTopWrapper = styled.div`
  ${mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const StyledArrows = styled.div`
  display: none;
  gap: 1.75rem;
  margin-bottom: 1.18rem;

  button {
    background: none;
    border: none;
    height: auto;
    width: auto;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .carousel {
    &__next, &__prev {
      svg {
        border-radius: 50%;
        ${(props) => props.theme.key !== 'zsl' &&
          `border: 1px solid ${props.theme.colors.carousel.paginationArrowCircleBorder};`
        }

        circle {
          fill: ${(props) => props.theme.colors.carousel.paginationArrowCircle};
        }
        path {
          fill: ${(props) => props.theme.colors.carousel.paginationArrowPath};
        }
        &:hover {
          circle {
            fill: ${(props) => props.theme.colors.carousel.paginationArrowCircleHover};
          }
        }
      }
    }
  }

  button.swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }

  img {
    display: block;
  }

  ${mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;

  gap: 0.5rem;
  margin-top: 1.5rem;

  .swiper-pagination-bullet {
    display: block;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    border-radius: ${(props) => props.theme.key === 'zsl' ? '0.25rem' : '50%'};
    opacity: ${(props) => props.theme.key === 'zsl' ? '1' : '0.2'};
    background-color: ${(props) => props.theme.colors.carousel.paginationBullet};
    margin: 0 !important; /* Hate to add it but swiper overrides it :'( */

    ${mediaQueries.lg} {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: ${(props) => props.theme.colors.carousel.paginationBulletActive};
  }

  ${mediaQueries.lg} {
    gap: 0.75rem;
    margin-top: 2.3rem;
  }
`;

export const StyledOverviewAnchorWrapper = styled.div`
  ${(props) => BorderRadius(props.theme.config.carouselAnchorRadius)};
  overflow: hidden;
`;

export const StyledOverviewAnchor = styled.div`
  ${h4}
  :hover {
  cursor: pointer;
  }
  color: ${(props) => props.theme.colors.carousel.paginationOverviewAnchorText};
  background: ${(props) => props.theme.colors.carousel.paginationOverviewAnchorBg};
  ${(props) => BorderRadius(props.theme.config.carouselAnchorRadius)}
  height: 272px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  text-align: center;
  position: relative;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }

  span {
    margin-bottom: 1rem;
  }

  img.pattern-texture-e {
    display: block;
    width: 228px;
    height: 228px;
    left: calc(50% - 228px/2);
    bottom: -155px;
    position: absolute;
  }

  svg.arrow-svg {
    height: 1rem;
    width: auto;

    ${mediaQueries.md} {
      height: auto;
      width: 2.3rem;
    }
  }

  ${mediaQueries.md} {
    height: 522px;
  }
`;
